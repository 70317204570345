<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <el-page-header @back="goBack" content="订单详情"> </el-page-header>
      </div>
      <el-card class="box-card">
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex">
            <div style="width: 60px; margin-top: 10px">备注：</div>
            <el-input v-model="form.remark" placeholder="请输入内容"></el-input>
          </div>
          <el-button type="text" size="small" @click="serveremark"
            >保存备注</el-button
          >
        </div>
      </el-card>
      <el-card class="box-card" style="width: 600px; margin: 20px 0">
        <div style="display: flex; justify-content: space-between">
          <div>
            <div>订单号：{{ form.orderNum }}</div>
            <div>买家：{{ form.userPhone }}</div>
            <div>应付金额：{{ form.payableAmount }}</div>
            <div>实付金额：{{ form.payAmount }}</div>
            <div>买家留言：{{ form.buyRemark }}</div>
            <div>退款编号：{{ form.cancelNum }}</div>
            <div>付款方式：{{ form.payType }}</div>
            <div>下单时间：{{ form.orderTime }}</div>
            <div>付款时间：{{ form.payTime }}</div>
            <div>完成时间：{{ form.finishTime }}</div>
          </div>
          <div>{{ map[form.orderState] }}</div>
        </div>
      </el-card>
      <el-table :data="tableData" border style="width: 100%">
        <!-- <el-table-column prop="coverImage" label="商品封面" width="180">
        </el-table-column> -->
        <el-table-column prop="coverImage" label="商品封面" width="90" fixed>
          <template slot-scope="scope">
            <img
              class="bgimg"
              :src="scope.row.coverImageUrl ? scope.row.coverImageUrl : img1"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称" width="180">
        </el-table-column>
        <el-table-column prop="isState" label="商品状态"> </el-table-column>
        <el-table-column prop="sellingPrice" label="单价"> </el-table-column>
        <el-table-column prop="sellingValue" label="数量"> </el-table-column>
        <!-- <el-table-column prop="" label="优惠"> </el-table-column> -->
        <el-table-column prop="payAmount" label="小计"> </el-table-column>
        <!-- <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" disabled>修改价格</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <div style="margin-top: 20px">商品总价：{{ form.payableAmount }}</div>
      <div style="margin-top: 20px">实付金额：{{ form.payAmount }}</div>
    </el-card>
  </div>
</template>

<script>
import * as orderApi from '@/api/order'
export default {
  data() {
    return {
      form: {},
      orderNum: '',
      map: {
        '': '全部',
        0: '待付款',
        10: '待预约',
        20: '服务中',
        30: '已完成',
        40: '退订',
      },
    }
  },
  mounted() {
    this.orderNum = this.$route.query.orderNum
    this.getInfo()
  },
  methods: {
    getInfo() {
      orderApi
        .queryOrderManageInfoByNum({
          orderNum: this.orderNum,
        })
        .then((res) => {
          this.form = res.data
          this.tableData = [res.data]
        })
    },
    goBack() {
      this.$router.push({
        path: '/order',
        query: {
          // pageN: this.currentPage,
          // id: res.data.data,
        },
      })
    },
    serveremark() {
      orderApi
        .updateRemark({
          orderNum: this.form.orderNum,
          remark: this.form.remark,
        })
        .then((res) => {
          this.$message({
            type: 'success',
            message: '编辑成功!',
          })
          this.getInfo()
        })
      // this.form.remark
    },
  },
}
</script>

<style lang="scss" scoped>
.bgimg {
  width: 60px;
  height: 60px;
}
</style>
